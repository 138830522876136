
import { defineComponent, ref } from 'vue'
import OrderListTab from '@/components/order/OrderListTab.vue'

export default defineComponent({
	name: 'OrderView',
	components: { OrderListTab },
	setup() {
		const activeTab = ref('LIST')
		return {
			activeTab,
		}
	}
})
