
import { computed, defineComponent, onMounted, reactive } from 'vue'
import dayjs, { convertToDate, convertToTime, formatDefault } from '@/utils/dayjs'
import { getOrderList } from '@/api/order/order.api'
import { getCourierList } from '@/api/courier/courier.api'
import { STATUS_ORDER_LIST, STATUS_COURIER } from '@/constants/courier'
import { ORDER_PAYMENT_CHANNEL, ORDER_STATUS_CHANNEL, ORDER_STATUS } from '@/type/order'
import { SearchOrderParams } from '@/api/order/order.type'
import { courierMap } from '@/constants/courier'
import { useRoute, useRouter } from 'vue-router'
import { Select,  } from '@element-plus/icons'
import { getShopList } from '@/api/shop/shop.api'
import { mapCourierLinkTracking, mapRejectTrackingRef } from '@/utils/courier'
import { useStore } from '@/store'
import OrderUpdateOrderDiff from '@/components/order/OrderUpdateOrderDiff.vue'
import OrderCreateOderWeightContrast from '@/components/order/OrderCreateOderWeightContrast.vue'
import OderCalculateWeightContrast from '@/components/order/OderCalculateWeightContrast.vue'
import OrderChangeStatusDialog from '@/components/order/OrderChangeStatusDialog.vue'
import { useCourierStore } from '@/pinia/courier/courier.pinia'
import { WEIGHT_CONTRAST_TYPE } from '@/constants/weight-contrast'

export default defineComponent({
	name: 'OrderListTab',
	components: {
		Select,
		OrderUpdateOrderDiff,
		OrderCreateOderWeightContrast,
		OrderChangeStatusDialog,
		OderCalculateWeightContrast,
	},
	setup() {
		const courierStore = useCourierStore()
		const store = useStore()
		const route = useRoute()
		const router = useRouter()
		const state = reactive<any>({
			offset: 1,
			limit: 10,
			pages: 0,
			totals: 0,
			orderList: [],
			search: null,
			shops: [],
			shopList: [],
			isFetchingOption: false,
			datePick: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
			paymentType: null,
			paymentList: [
				{
					value: ORDER_PAYMENT_CHANNEL.ALL,
					label: 'ทั้งหมด'
				},
				{
					value: ORDER_PAYMENT_CHANNEL.COD,
					label: 'เก็บเงินปลายทาง'
				},
				{
					value: ORDER_PAYMENT_CHANNEL.TRANSFER,
					label: 'โอนเงิน'
				}
			],
			status: null,
			referralCode: null,
			statusList: STATUS_ORDER_LIST,
			courier: null,
			courierList: [],
			isFetching: false,
			isEdit: false,
			isCreateWeightConOrder: false,
			isCalculateWeightConOrder: false,
			orderSelected: null,
			isShowChangeStatusDialog: false,
		})

		const isDisabledFetchButton = computed(() => {
			// state.shops?.length <= 0 && !state.search && state.search?.length < 9
			if (state.shops?.length) {
				return false
			}
			if (!state.search || state.search?.length < 9) {
				return true
			}
			return false
		})
		const claims = computed(() => store.state.user.claims)

		const remoteMethod = async (query: string) => {
			if (query) {
				state.isFetchingOption = true
				try {
					const queryParams = {
						search: query,
						referralCode: null,
						offset: 1,
						limit: 99999999,
					}
					const {  data: { data: shopList } } = await getShopList(queryParams)
					state.shopList = shopList.map((shop: any) => ({ label: shop.name, value: shop._id }))
				} catch (error) {
					console.error(error)
				} finally {
					state.isFetchingOption = false
				}
			} else {
				state.shopList = []
			}

		}

		const fetchOrderList = async () => {
			try {
				state.isFetching = true
				const params: SearchOrderParams & { [key: string]: any } = {
					offset: state.offset,
					limit: state.limit,
					status: state.status,
					shops: state.shops,
					paymentChannel: state.paymentType,
					startDate: state.datePick[0],
					endDate: state.datePick[1],
					search: state.search,
					courier: state.courier,
				}

				const finalQueryString: any = {}
				for (const key in params) {
					if (params[key] && key !== 'shops') {
						finalQueryString[key] = params[key]
					}
				}

				await router.push({ query: finalQueryString })

				const { data: { data, pagination } } = await  getOrderList(params)

				state.orderList = courierStore.mapCourierWithOrder({data}).data
				state.pages = pagination.pages
				state.totals = pagination.totals

			} catch (error) {
				console.error(error)
			} finally {
				state.isFetching = false
			}
		}

		const onEditOrder = (item: any) => {
			state.isEdit = true
			state.orderSelected = item
		}

		const onCreateWeightConOrder = (item: any) => {
			state.isCreateWeightConOrder = true
			state.orderSelected = item
		}

		const onCalculateWeightConOrder = (item: any) => {
			state.isCalculateWeightConOrder = true
			state.orderSelected = item
		}

		const showDialogAdjustOrder = (row: any) => {
			//
			state.orderSelected = row
			state.isShowChangeStatusDialog = true
		}

		onMounted(() => {
			if (route.query) {
				for (const key in route.query) {
					if (state[key] !== undefined) {
						state[key] = route.query[key]
					}

					const numberKeys = ['offset', 'limit']
					if (numberKeys.includes(key)) {
						state[key] = Number(route.query[key])
					}

					if (key === 'startDate' || key === 'endDate') {
						state.datePick = [...state.datePick, route.query[key]]
					}
				}
			}
			// set param from query string
			getCourierList()
				.then((response) => {
					state.courierList = response
				})
			// Do not fetch on mounted it will effective to performance
			// fetchOrderList()
		})

		return {
			state,
			convertToDate,
			convertToTime,
			ORDER_STATUS_CHANNEL,
			ORDER_STATUS,
			STATUS_COURIER,
			fetchOrderList,
			formatDefault,
			courierMap,
			remoteMethod,
			mapCourierLinkTracking,
			isDisabledFetchButton,
			claims,
			onEditOrder,
			onCreateWeightConOrder,
			showDialogAdjustOrder,
			mapRejectTrackingRef,
			onCalculateWeightConOrder,
			WEIGHT_CONTRAST_TYPE
		}
	}
})
